
import './App.css';
import Chart from "chart.js/auto";
import { CategoryScale, 
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend } from "chart.js";
import { Line } from 'react-chartjs-2';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


const PrettoSlider = styled(Slider)({
  color: '#ff6384',
})

function valueLabelFormat(value) {
  const pct = value/(100.0*12)
  const r = Math.round(pct*1000000)

  return `${value}%\n ${r} kr/månad`;
}

function convertToSwedishMonthYearArray(dateArray) {
  const swedishMonths = [
      "januari", "februari", "mars", "april", "maj", "juni",
      "juli", "augusti", "september", "oktober", "november", "december"
  ];

  const resultArray = dateArray.map(date => {
      const month = swedishMonths[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
  });

  return resultArray;
}


const footer = (tooltipItem) => {
  const pct = tooltipItem[0].raw/(100.0*12)
  const r = Math.round(pct*1000000)
  return 'Ränta/månad: ' + r + ' kr' //+ Math.round((tooltipItem.parsed.y/100)*(1000000/12))
};

Chart.register(CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

  export const options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Interaktiv graf över räntekostnaden i månaden för ett lån på 1 000 000 kr',
      },
      tooltip: {
        callbacks: {
          footer: footer,
        }
      },
    },
    scales: {
      scaleOverride: true,
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          stepSize: 0.25
        },
        min: 2,
        max: 7
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          stepSize: 500
        },
        min: 1667,
        max: 5833,
        grid: {
          drawOnChartArea: false,
        },
      },
  
    },

  };
  
  const data1 = [        4  , 4.1       , 4.14        , 4.15      , 4.15      , 4.15      , 4.15      ,4.15       , 4.15      , 4.12    , 4.06     , 3.98,      3.88]
  
  
function App() {

  const [r, setR] = useState(4);
  const [labels,setLabels] = useState(['23 Maj','23-07'  , '23-10'   , '24-01' , '24-04' , '24-07' , '24-10' ,'25-01'  , '25-04', '25-07', '25-10', '26-01','26-04'])
  const [data,setData] = useState({
    labels,
    datasets: [
      {
        label: 'Ränta som följer riksbankens räntebana',
        data: data1,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
    ],
  })
  const [ircurve,setIrcurve] = useState([0, 0.1, 0.14, 0.15, 0.15 , 0.15, 0.15,0.15, 0.15, 0.12, 0.06, -0.02, -0.12])

  React.useEffect(() => {
    fetch("https://calcbo.se/api/snittrantas/prognosis", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData == null || responseData.irPoints == null || responseData.irPoints.length <= 0) {
            } else {
                const nfix =responseData.irPoints.map((a) => ({'rate': Number(a.rate),'date': new Date(a.date)}))
                setR(nfix[0].rate)
                var labels = nfix.map((a) => (a.date))
                labels = convertToSwedishMonthYearArray(labels)
                setLabels(labels)
                const ircn = nfix.map((a)=>a.rate-nfix[0].rate)
                const newdata = nfix.map((a)=>a.rate)
                setIrcurve(ircn);
                setData({
                  labels,
                  datasets: [
                    {
                      label: 'Ränta som följer riksbankens räntebana',
                      data: newdata,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      yAxisID: 'y',
                      cubicInterpolationMode: 'monotone',
                      tension: 0.4
                      },
                  ],
                })
            }
        })
        .catch((err) => {
            console.error(err)
        });
}, []); // eslint-disable-line react-hooks/exhaustive-deps



  const handleRChange = (event) => {
    setR(event.target.value);
    const newdata = ircurve.map(v => v+event.target.value)
    setData({
      labels,
      datasets: [
        {
          label: 'Ränta som följer riksbankens räntebana',
          data: newdata,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y',
          cubicInterpolationMode: 'monotone',
          tension: 0.4
          },
      ],
    })
  };


  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }


  const rml = window.innerWidth < window.innerHeight*0.8 ? 3 : 5
  const rheight = window.innerWidth < window.innerHeight*0.8 ? 215 : 515

  return (
    <div>
      <Box sx={{ height: rheight,zIndex: 1000,position: 'absolute',ml: rml, mt: 8}} >
      <PrettoSlider
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical',
          },
        }}
        orientation="vertical"
        value={r}
        onChange={handleRChange}
        aria-label="Start ränta"
        valueLabelDisplay="auto"
        min={2}
        step={0.25}
        max={7}
        onKeyDown={preventHorizontalKeyboardNavigation}
        valueLabelFormat={valueLabelFormat}
      />
    </Box>
    <Line options={options} data={data} />
    </div>
  );
}

export default App;
